import $axios from '@/utils/request'
import $API from '@/apis/api-types'

export const batchSetCategory = (resourceIds, resourceType, categoryIds) => {
  const params = {
    resourceType,
    resourceIds: resourceIds.filter(it => it),
    categoryIds: categoryIds.filter(it => it)
  }
  return $axios.post($API.batchSetCategory, params)
}
