<template>
  <el-dialog
    class="customize-el-dialog"
    title="分类"
    :visible.sync="isShowVisible"
    width="500px"
    @closed="onClosed"
    @open="onOpen"
  >
    <el-checkbox-group v-if="categoriesList.length > 0" :value="radio" @input="handleRadioChange">
      <el-checkbox
        v-for="item in categoriesList"
        :key="item.categoryId"
        :label="item.categoryId"
      >
        {{ item.title }}
      </el-checkbox>
    </el-checkbox-group>
    <div v-else>
      <el-empty description="暂无分类" />
    </div>
    <template slot="footer">
      <el-button @click="isShowVisible = false">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ClassifyDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    categoriesList: {
      type: Array,
      default: () => []
    },
    submitLoading: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radio: []
    }
  },
  computed: {
    isShowVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleRadioChange(val) {
      this.radio = this.radio === val ? [] : [val.pop()]
    },
    onClosed() {
      this.radio = []
    },
    onOpen() {
      this.radio = this.categoryId
    },
    handleConfirm() {
      this.$emit('onConfirm', this.radio)
    }
  }
}
</script>
